<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 65vw; min-height: 80vh;">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">
          {{ $t('Task') }}
        </div>

        <q-space />

        <q-btn
            v-close-popup
            icon="close"
            flat
            round
            dense
        />
      </q-card-section>

      <q-card-section>
        <q-splitter
            v-model="splitterModel"
            style="height: 100%;"
        >
          <template v-slot:before>
            <q-tabs
                v-model="currentTab"
                vertical
                class="text-teal"
            >
              <q-tab
                  name="selectedData"
                  :label="$t('Data')"
              />

              <q-tab
                  name="requests"
                  :label="$t('Results')"
              />
            </q-tabs>
          </template>

          <template v-slot:after>
            <q-tab-panels
                v-model="currentTab"
                animated
                swipeable
                vertical
                transition-prev="jump-up"
                transition-next="jump-up"
            >
              <q-tab-panel name="requests">
                <div v-if="data">
                  <q-tabs v-model="dataTab">

                    <q-tab
                        v-if="data.result"
                        name="result"
                        :label="$t('Result')"
                    />

                    <q-tab
                        v-if="data.exportResult"
                        name="exportResult"
                        :label="$t('Export Result')"
                    />
                  </q-tabs>

                  <q-separator />

                  <q-tab-panels v-model="dataTab" animated>

                    <q-tab-panel name="result" v-if="data.result">
                      <object-code :data="data.result" />
                    </q-tab-panel>

                    <q-tab-panel name="exportResult" v-if="data.exportResult">
                      <object-code :data="data.exportResult" />
                    </q-tab-panel>
                  </q-tab-panels>
                </div>
              </q-tab-panel>

              <q-tab-panel name="selectedData">
                <form-builder :schema="schema" />
              </q-tab-panel>
            </q-tab-panels>
          </template>
        </q-splitter>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>

// Components
import ObjectCode from '../history-collapse/ObjectCode.vue'

// Utils
import { doCopy } from '../../utils/copy-utils'
import {mapActions} from "vuex";

export default {
  name: 'ShipmentTaskModal',
  components: { ObjectCode },
  data () {
    return {
      dataTab: 'result',
      isOpen: false,
      data: null,
      currentTab: 'selectedData',
      splitterModel: 20,
      statuses: [
        { id: 'new', name: this.$t('New') },
        { id: 'packing', name: this.$t('Packing') },
        { id: 'packed', name: this.$t('Packed')},
        { id: 'measuring', name: this.$t('Measuring') },
        { id: 'measured', name: this.$t('Measured') },
        { id: 'sorting', name: this.$t('Sorting') },
        { id: 'calculated', name: this.$t('Calculated') },
        { id: 'prepared', name: this.$t('Prepared') },
        { id: 'exported', name: this.$t('Exported') },
        { id: 'cancel', name: this.$t('Cancel') },
        { id: 'closed', name: this.$t('Closed') },
        { id: 'deleted', name: this.$t('Deleted') },
      ],
    }
  },
  watch: {
    isOpen (value) {
      if (!value) {
        this.isOpen = false
      }
    },
    isOpenDebugModal (value) {
      if (!value) {
        return
      }

      this.isOpen = value
    }
  },
  computed: {
    queueName () {
      if (this.data && this.data._embedded && this.data._embedded.queue){
        return `${this.data._embedded.queue.name} [${this.data._embedded.queue.id}]`
      }
        return 'none'
    },
    executiveName () {
      if (this.data && this.data._embedded && this.data._embedded.executive){
        return `${this.data._embedded.executive.email} [${this.data._embedded.executive.id}]`
      }
      return 'none'
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'select',
                label: this.$t('Status'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                field: 'state',
                value: this.data?.state,
                options: this.statuses,
                required: true,
                customLabel (row) {
                  return row && typeof row === 'object'
                      ? row.name
                      : row
                },
                onChange: (value) => {
                  this.updatePreprocessingTask({state: value.id})
                      .then(()=> {
                        this.data.state = value.id
                      })
                },
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Queue'),
                field: 'queue',
                value: this.queueName,
                disabled: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Delivery Request'),
                field: 'deliveryRequest',
                value: this.data && this.data._embedded && this.data._embedded.deliveryRequest && this.data._embedded.deliveryRequest.id,
                disabled: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('ExtId'),
                field: 'extId',
                value: this.data && this.data.extId,
                disabled: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Executive'),
                field: 'executive',
                value: this.executiveName,
                disabled: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
              },
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions([
      'updatePreprocessingTask'
    ]),
    handleCopy () {
      doCopy(JSON.stringify(this.data))
    },
    handleClick (data) {
      this.currentTab = 'selectedData'
      this.data = null

      setTimeout(() => {
        this.data = data
      })
    },
    open (data) {
      this.isOpen = true
      this.data = data
    },
    close () {
      this.isOpen = false
    }
  }
}
</script>
