<template>
  <div>
    <q-list
      bordered
      separator
      class="rounded"
    >
      <q-item
        v-if="title"
        class="block text-center"
      >
        <h6 class="q-my-none">
          {{ title }}
        </h6>
      </q-item>

      <q-item
        v-for="(place, i) in currentPlaces"
        :key="place.id"
        class="q-pa-none"
      >
        <div class="full-width">
          <div class="row items-center full-width border-bottom q-py-sm q-px-md">
            <q-btn
              color="negative"
              :disable="currentPlaces.length < 2"
              icon="delete"
              flat
              @click="handleRemovePlace(place)"
            />

            <h6 class="q-my-none q-ml-sm">
              {{ $t('Box No') + (i + 1) }}
            </h6>

            <q-space />

            <q-btn
              color="light-blue-9"
              :label="$t('Add product')"
              no-caps
              @click="handleAddItem(place)"
            />
          </div>

          <q-table
            row-key="id"
            class="full-width table--only-header"
            :rows-per-page-label="$t('Rows per page')"
            :rows="place.items || []"
            :columns="columns"
            :pagination="{ page: 1, rowsPerPage: 250, totalRowsNumber: (place.items || []).length }"
            virtual-scroll
            flat
          >
            <template v-slot:body="props">
              <product-row
                :data="props"
                @dblclick="handleEditItem(place, props.row)"
                @remove="handleRemove(place, props.row)"
              />
            </template>

            <template v-slot:bottom-row>
              <q-tr>
                <q-td class="border-top border-right text-center">
                  {{ $t('Total') }}
                </q-td>

                <q-td class="border-top" />

                <q-td class="border-top" />

                <q-td class="border-top" />

                <q-td class="border-top" />

                <q-td class="border-top" />

                <q-td class="border-top">
                  {{ getPlaceItemsCount(place) }}
                </q-td>

                <q-td class="border-top">
                  {{ getPlaceTotalSum(place).toFixed(2) }}
                </q-td>

                <q-td class="border-top" />
              </q-tr>
            </template>
          </q-table>
        </div>
      </q-item>

      <q-item
        v-if="currentPlaces.length > 0"
        class="justify-center text-h6"
      >
        <span class="q-mr-md">{{ $t('Total') + ': ' + total.price.toFixed(2) }}</span>

        <span>{{ $t('Total Items') + ': ' + total.count }}</span>
      </q-item>

      <q-item
        v-else
        class="justify-center text-subtitle1 items-center"
      >
        <span>
          {{ $t('There is no boxes with products. ') }}
        </span>

        <span>
          {{ $t('You can add box via button below.') }}
        </span>
      </q-item>

      <q-item class="justify-center items-center q-pb-md">
        <q-btn
          color="light-blue-9"
          :label="$t('Add box')"
          @click="handleAddPlace"
        />
      </q-item>
    </q-list>

    <d-r-product
      ref="drProduct"
      @submit="handleItemSubmit"
    />

    <confirm-modal ref="confirmModal" />
  </div>
</template>

<script>
// Components
import ConfirmModal from '../confirm-modal/ConfirmModal.vue'
import DRProduct from './DRProduct.vue'
import ProductRow from './ProductRow.vue'

export default {
  name: 'DRProducts',
  emits: ['change'],
  components: {
    DRProduct,
    ProductRow,
    ConfirmModal
  },
  props: {
    title: {
      type: String
    },
    places: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      place: null,
      product: null,
      currentPlaces: [],
      columns: [
        {
          name: 'selected',
          label: `${this.$t('Mark')} (${this.$t('optional')})`,
          align: 'center',
          classes: 'border-right',
          headerClasses: 'border-right'
        },
        {
          name: 'image',
          label: this.$t('Image'),
          align: 'left'
        },
        {
          name: 'id',
          label: this.$t('Id'),
          align: 'left'
        },
        {
          name: 'name',
          label: this.$t('Name'),
          align: 'left'
        },
        {
          name: 'payment',
          label: this.$t('Payment'),
          align: 'left'
        },
        {
          name: 'estimatedCost',
          label: this.$t('Estimated Cost'),
          align: 'left'
        },
        {
          name: 'count',
          label: this.$t('Count'),
          align: 'left'
        },
        {
          name: 'total',
          label: this.$t('Total'),
          align: 'left'
        },
        {
          name: 'actions',
          label: '',
          align: 'right'
        }
      ]
    }
  },
  computed: {
    total () {
      return this.currentPlaces.reduce((acc, place) => {
        acc.price += this.getPlaceTotalSum(place)
        acc.count += place.items.reduce((acc, item) => {
          return acc + Number(item.count || 0)
        }, 0)

        return acc
      }, { count: 0, price: 0 })
    }
  },
  watch: {
    places (value) {
      this.currentPlaces = this.copyPlaces(value)
    }
  },
  mounted () {
    this.currentPlaces = this.copyPlaces(this.places)
  },
  methods: {
    getPlaceItemsCount (place) {
      return place.items.reduce((sum, item) => {
        return sum + (Number(item.count) || 0)
      }, 0)
    },
    getPlaceTotalSum (place) {
      return place.items.reduce((sum, item) => {
        return sum + ((Number(item.count) || 0) * (Number(item.payment) || 0))
      }, 0)
    },
    copyPlaces (places) {
      return places.map(place => {
        return {
          ...place,
          items: place.items.map(item => {
            return {
              ...item,
              dimensions: !item.dimensions || typeof item.dimensions === 'string'
                ? { x: 0, y: 0, z: 0 }
                : item.dimensions
            }
          })
        }
      })
    },
    handleItemSubmit (item) {
      let updatedPlace

      this.currentPlaces = this.currentPlaces.map(p => {
        if (p.id === this.place.id) {
          let hasItem = false

          p.items = p.items.map(i => {
            if (i.extId === item.extId) {
              hasItem = true
              return item
            }

            return i
          })

          if (!hasItem) {
            p.items.push(item)
          }

          updatedPlace = p
        }

        return p
      })

      if (updatedPlace) {
        this.$emit('change', this.currentPlaces)
      }

      this.handleCloseItemModal()
    },
    handleEditItem (place, item) {
      this.place = place
      this.product = item
      this.$refs.drProduct.open(item)
    },
    handleRemovePlace (place) {
      this.currentPlaces = this.currentPlaces.filter(({ id }) => id !== place.id)
      this.$emit('change', this.currentPlaces)
    },
    handleAddPlace () {
      this.currentPlaces = [...this.currentPlaces, { id: Math.floor(Math.random() * (9999999999) + 1), items: [] }]
      this.$emit('change', this.currentPlaces)
    },
    handleRemove (place, item) {
      this.currentPlaces = this.currentPlaces.map(p => {
        if (p.id === place.id) {
          p.items = p.items.filter(i => i.extId !== item.extId)
        }

        return p
      })

      this.$emit('change', this.currentPlaces)
    },
    handleAddItem (place) {
      this.place = place
      this.product = null
      this.$refs.drProduct.open()
    },
    handleCloseItemModal () {
      this.$refs.drProduct.close()
    },
    createNewItem (item) {
      const blockedFields = ['_links', 'id', '_embedded', 'created', 'updated', 'price']

      const newItem = Object.keys(item).reduce((c, key) => {
        if (blockedFields.includes(key)) {
          return c
        }

        if (item[key] === undefined || item[key] === null) {
          return c
        }

        c[key] = item[key]
        return c
      }, {})

      if (!newItem.dimensions || typeof newItem.dimensions === 'string') {
        newItem.dimensions = {
          x: 1,
          y: 1,
          z: 1
        }
      }

      return newItem
    }
  }
}
</script>
