<template>
  <json-viewer
    :value="data"
    :expand-depth="1"
    copyable
    sort
  />
</template>

<script>
export default {
  name: 'ObjectCode',
  props: {
    data: {
      default () {
        return {}
      }
    }
  }
}
</script>
