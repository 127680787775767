<template>
  <q-dialog
    v-model="isOpen"
    :maximized="isFullscreen"
  >
    <div
      class="relative styled-scrollbar"
      style="width: 500px; max-width: 100vw;"
      :class="$q.dark.isActive ? 'bg-dark text-white' : 'bg-white text-dark'"
    >
      <div class="row items-center justify-between border-bottom q-pa-md">
        <h5 class="q-my-none">
          {{ $t('Chat') }}
        </h5>

        <q-btn
          v-close-popup
          icon="close"
          flat
          round
          dense
        />
      </div>

      <div v-if="isOpen">
        <chat
          :entities="entities"
          :height="height"
          :is-fullscreen="isFullscreen"
          :options="options"
        />
      </div>
    </div>
  </q-dialog>
</template>

<script>
// Components
import Chat from './Chat'

// Quasar
import { debounce } from 'quasar'

export default {
  name: 'ChatModal',
  components: {
    Chat
  },
  data () {
    return {
      isOpen: false,
      entities: [],
      options: {},
      isFullscreen: false
    }
  },
  computed: {
    height () {
      return this.isFullscreen
        ? 100
        : 90
    }
  },
  mounted () {
    this.resize()
    this.resize = debounce(this.resize.bind(this), 500)

    window.addEventListener('resize', this.resize)
  },
  unmounted () {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize () {
      this.isFullscreen = window.innerWidth < 600
    },
    open (entities, options = {}) {
      this.entities = entities
      this.options = options
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    }
  }
}
</script>
