<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 80vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Products') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section>
        <d-r-products
          v-if="id"
          :disable="disabled"
          :places="places"
          :external-delivery-request="id"
          @change="handlePlaceChange"
        />

        <d-r-products-static
          v-else
          :places="places"
          @change="handlePlaceChange"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Components
import DRProducts from '../products/DRProducts.vue'
import DRProductsStatic from '../products/DRProductsStatic.vue'

export default {
  name: 'DRProductsModal',
  emits: ['change'],
  components: {
    DRProducts,
    DRProductsStatic
  },
  data () {
    return {
      isOpen: false,
      disabled: false,
      places: [],
      id: null
    }
  },
  methods: {
    handlePlaceChange (places) {
      this.$emit('change', places)
    },
    open (id, places, disabled) {
      this.id = id
      this.getPlaces()
      this.disabled = disabled
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    },
    getPlaces () {
      const query = {
        filter: [
          { type: 'eq', field: 'deliveryRequest', value: this.id }
        ],
        'order-by': [
          { type: 'field', field: 'priority', direction: 'asc' }
        ],
        'per_page': 250
      }

      return this.$service.deliveryServicePlace.getAll(query)
        .then(({ items }) => {
          this.places = items
        })
    },
  }
}
</script>
