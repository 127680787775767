<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 70vh;">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">
          {{ $t('Dimensions') }}
        </div>

        <q-space />

        <q-btn
          v-close-popup
          icon="close"
          flat
          round
          dense
        />
      </q-card-section>

      <q-card-section>
        <form-builder :schema="schema" />

        <div
          v-if="errors.length > 0"
          class="text-negative q-pa-md text-center"
        >
          <div
            v-for="(error, i) in errors"
            :key="i"
            class="q-pa-xs"
          >
            {{ $t(error) }}
          </div>
        </div>
      </q-card-section>

      <q-card-section class="row justify-end">
        <div class="q-mr-sm">
          <q-btn
            color="dark"
            text-color="white"
            size="sm"
            :label="$t('Cancel')"
            no-caps
            unelevated
            @click="close"
          />
        </div>

        <q-btn
          color="light-blue-9"
          text-color="white"
          size="sm"
          :label="$t('Export')"
          no-caps
          unelevated
          @click="saveTaskDimensions"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  name: 'DeliveryRequestDimensions',
  data () {
    return {
      request: null,
      isOpen: false,
      errors: [],
      model: {
        weight: 100,
        dimensions: {
          x: 100,
          y: 100,
          z: 100
        }
      }
    }
  },
  computed: {
    ...mapGetters([
        'settings',
        'appOptions'
    ]),
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'number',
                label: this.$t(`Weight (${this.appOptions.defaultDimensions.weightUnit})`),
                field: 'weight',
                value: this.model.weight,
                required: true,
                wrapperStyleClasses: 'col-6 q-pa-xs',
                onChange: (value) => {
                  this.model.weight = value
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'x',
                value: this.model.dimensions.x,
                required: true,
                label: this.$t(`Width (${this.appOptions.defaultDimensions.dimensionUnit})`),
                wrapperStyleClasses: 'col-6 q-pa-xs',
                onChange: (value) => {
                  this.model.dimensions.x = value
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'y',
                value: this.model.dimensions.y,
                required: true,
                label: this.$t(`Height (${this.appOptions.defaultDimensions.dimensionUnit})`),
                wrapperStyleClasses: 'col-6 q-pa-xs',
                onChange: (value) => {
                  this.model.dimensions.y = value
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'z',
                value: this.model.dimensions.z,
                required: true,
                label: this.$t(`Length (${this.appOptions.defaultDimensions.dimensionUnit})`),
                wrapperStyleClasses: 'col-6 q-pa-xs',
                onChange: (value) => {
                  this.model.dimensions.z = value
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'updateDeliveryRequest',
      'setMode'
    ]),
    ...mapActions([
      'loadDeliveryRequestByBarcode'
    ]),
    saveTaskDimensions () {
      return this.$service.deliveryRequestProcess.processByID(this.request.id, [], this.model)
        .then(() => {
          this.close()
        })
    },
    loadDimensions () {
      const { weight, width, length, height } = this.settings.default

      if (!this.request.weight) {
        this.model.weight = weight
        this.errors.push('Shipment don\' have weight. Defaults will be used.')
      } else {
        this.model.weight = this.request.weight
      }

      if (!this.request.dimensions) {
        this.errors.push('Shipment don\' have dimensions. Defaults will be used.')
        this.model.dimensions = {
          x: width,
          y: height,
          z: length
        }
      } else {
        this.model.dimensions = this.request.dimensions
      }
    },
    open (request) {
      this.request = request
      this.loadDimensions()
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    }
  }
}
</script>
